import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";
import {
  AutoComplete,
  BackTop,
  Button,
  Card,
  Input,
  Radio,
  RadioChangeEvent,
  Select,
} from "antd";
import { BaseOptionType, DefaultOptionType } from "antd/lib/select";

import ADMIN_COMMON_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_COMMON_QUERY";
import PARTNER_QUERY from "@sellernote/_shared/src/queries/forwarding/PARTNER_QUERY";
import { Country } from "@sellernote/_shared/src/types/common/common";
import { changeFetchCountryParam } from "@sellernote/_shared/src/utils/forwarding/admin/partner";
import Layout from "@sellernote/_shared-for-forwarding-admin/src/containers/Layout";
import withRequireAuth from "@sellernote/_shared-for-forwarding-admin/src/hocs/withRequireAuth";

import Loading from "../../../components/Loading";

import PartnerListTable from "../PartnerListTable";
import RegisterPartner from "../RegisterPartner";

const InputGroup = Input.Group;

const options = [
  { label: "FCL", value: "FCL" },
  { label: "LCL", value: "LCL" },
  { label: "AIR", value: "AIR" },
  { label: "특송", value: "EXPRESS" },
];

const PartnerList = () => {
  const { businessArea }: { businessArea: string } = useParams();

  const [currentPage, setCurrentPage] = useState(1);
  const [transportMode, setTransportMode] = useState("FCL");
  const [country, setCountry] = useState("전체");
  const [searchText, setSearchText] = useState("");
  const [isAutoFetch, setIsAutoFetch] = useState(true);
  const [region, setRegion] = useState("KR");

  const { data, refetch: refetchPartnerList } = PARTNER_QUERY.useGetPartnerList(
    {
      page: currentPage - 1,
      perPage: 25,
      transportMode,
      businessArea,
      companyName: searchText,
      country: changeFetchCountryParam(country),
      region,
      enabled: isAutoFetch,
    }
  );

  const handlePartnerListRefetch = () => {
    refetchPartnerList();
  };

  const { data: countryData } = ADMIN_COMMON_QUERY.useGetCountryList();

  const countryName = useMemo(() => {
    if (countryData === undefined) {
      return [];
    } else {
      const countryNameArr = countryData?.map((v: Country) => {
        const obj: { value: string } = { value: "" };
        obj.value = v.nameKR;
        return obj;
      });
      return countryNameArr;
    }
  }, [countryData]);

  const handleChangeTransportMode = (e: RadioChangeEvent) => {
    setTransportMode(e.target.value);
  };

  const changeCountryCode = (e: string) => {
    if (e === "전 세계") {
      return "ALL";
    }
    const findCountryData = countryData?.find((v: Country) => {
      return v.nameKR === e;
    });

    if (findCountryData) {
      return findCountryData.name;
    }
    return "전체";
  };

  const handleChangeInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isAutoFetch) {
      setIsAutoFetch(false);
    }
    setSearchText(e.target.value);
  };

  if (data === undefined || countryData === undefined) {
    return (
      <Layout breadcrumbs={["파트너 관리"]} title={"파트너 관리"}>
        <Loading />
      </Layout>
    );
  }

  return (
    <Layout breadcrumbs={["파트너 관리"]} title={"파트너 관리"}>
      <Card>
        <BackTop />

        <RegisterPartner
          countryData={countryData}
          handlePartnerListRefetch={handlePartnerListRefetch}
        />

        <div style={{ marginBottom: "10px" }}>
          <Select
            defaultValue={"KR"}
            style={{ width: "140px" }}
            onChange={(e: string) => setRegion(e)}
          >
            <Select.Option value="KR">한국</Select.Option>
            <Select.Option value="SG">싱가포르</Select.Option>
          </Select>
        </div>

        <Radio.Group
          options={options}
          onChange={handleChangeTransportMode}
          value={transportMode}
          optionType="button"
        />
        <AutoComplete
          style={{
            width: "100%",
            maxWidth: 200,
            marginLeft: 16,
          }}
          allowClear
          defaultValue={"전체"}
          onSelect={(e: string) => {
            setCountry(changeCountryCode(e));
          }}
          options={[{ value: "전체" }, { value: "전 세계" }, ...countryName]}
          filterOption={(
            inputValue: string,
            option: DefaultOptionType | BaseOptionType | undefined
          ) =>
            option?.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
          }
        />

        <div style={{ marginTop: 16, marginBottom: 16 }}>
          <InputGroup compact>
            <Select defaultValue={"company"} style={{ width: "140px" }}>
              <Select.Option value="company">{"회사명"}</Select.Option>
            </Select>
            <Input
              style={{ width: 243 }}
              onChange={handleChangeInputValue}
              allowClear
              onPressEnter={() => setIsAutoFetch(true)}
              value={searchText}
            />
            <Button shape="circle" onClick={() => setIsAutoFetch(true)}>
              <SearchOutlined />
            </Button>
          </InputGroup>
        </div>

        <PartnerListTable
          currentPage={currentPage}
          countryData={countryData}
          partnerList={data?.list}
          setCurrentPage={setCurrentPage}
          total={data?.total}
        />
      </Card>
    </Layout>
  );
};

export default withRequireAuth(PartnerList);
