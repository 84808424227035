import { useState } from "react";
import { useForm } from "react-hook-form";
import { Box } from "@mui/material";

import ADMIN_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_BID_QUERY";
import { Country, Port } from "@sellernote/_shared/src/types/common/common";
import { ApplyBidFormData } from "@sellernote/_shared/src/types/forwarding/adminBid";
import { InvoiceData } from "@sellernote/_shared/src/types/forwarding/tradingStatement";
import {
  ExchangeRate,
  TrelloBidDetail,
} from "@sellernote/_shared/src/types/forwarding/trello";
import {
  extractNumberFromRtonString,
  getExchangeDate,
} from "@sellernote/_shared/src/utils/forwarding/admin/tradingStatement";
import QuotationFeeForm from "@sellernote/_shared-for-forwarding-admin/src/components/QuotationFeeForm";

import useGetFormDefaultValue from "./hooks/useGetFormDefaultData";
import useSetDefaultCargoInfo from "./hooks/useSetDefaultCargoInfo";

import RequestButtonAndTotalPriceBottomStickyBar from "./RequestButtonAndTotalPriceBottomStickyBar";
import ShipmentInfo from "./ShipmentInfo";
import TemporaryWarningAndSendDate from "./TemporaryWarningAndSendDate";

const TradingStatementInfo = ({
  bidDetailData,
  portData,
  countryList,
  invoiceData,
  initialExchangeRateList,
  sessionInvoiceId,
  onSessionInvoiceChange,
  isImport,
}: {
  bidDetailData: TrelloBidDetail;
  portData: Port[];
  countryList: Country[];
  invoiceData: InvoiceData | undefined;
  initialExchangeRateList: ExchangeRate[];
  sessionInvoiceId: string | null;
  onSessionInvoiceChange: (invoiceId: string) => void;
  isImport: boolean;
}) => {
  // 항목의 아이템 목록을 가져온다.
  const { data: applyItems } = ADMIN_BID_QUERY.useGetAdminBidApplyItems({
    isImport,
  });

  const { formDefaultValue } = useGetFormDefaultValue({
    shipmentDetailData: bidDetailData,
    portData,
    countryList,
    invoiceData,
    initialExchangeRateList,
  });

  useSetDefaultCargoInfo({ shipmentDetailData: bidDetailData, invoiceData });

  const { control, watch, setValue } = useForm<ApplyBidFormData>({
    defaultValues: formDefaultValue,
  });

  // AIR 의뢰인 경우 Rton을 직접 입력한다.
  const [directAirRton, setDirectAirRton] = useState<number>(
    bidDetailData.freightType === "AIR" && invoiceData?.rton
      ? extractNumberFromRtonString(invoiceData.rton)
      : 0
  );

  const [exchangeRateDate, setExchangeRateDate] = useState(
    getExchangeDate({ invoiceData, shipmentDetailData: bidDetailData })
  );

  const handleDirectAirRtonChange = (rton: number) => setDirectAirRton(rton);

  const handleExchangeRateDate = (date: string) => setExchangeRateDate(date);

  /**
   * form에서 계산시 사용하기 위한 환율 리스트
   * - react-hook-form 상태에 저장된 환율 리스트 데이터를 사용
   * - 첫 진입시에는 최상단 페이지에서 요청한 initialExchangeList로 셋팅됨
   * - 이후 ShipmentInfo > ExchangeInfo에서 dynamicExchangeList 요청 성공 시 변경해줌
   */
  const formExchangeRateList = watch().invoiceExchangeRateList;

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <TemporaryWarningAndSendDate invoiceData={invoiceData} />

      <ShipmentInfo
        control={control}
        invoiceState={watch()}
        setValue={setValue}
        portData={portData}
        bidDetailData={bidDetailData}
        invoiceData={invoiceData}
        directAirRton={directAirRton}
        handleDirectAirRtonChange={handleDirectAirRtonChange}
        initialExchangeList={initialExchangeRateList}
        exchangeRateDate={exchangeRateDate}
        handleExchangeRateDate={handleExchangeRateDate}
      />

      <Box display="flex" flexDirection="column" gap={1}>
        {applyItems && (
          <QuotationFeeForm
            quotationFeeFormCategory={"localCost"}
            watch={watch}
            setValue={setValue}
            templateType="bidApply"
            exchangeRateList={formExchangeRateList}
            applyShipmentFormFeeDataType={"localFeeData"}
            control={control}
            applyItems={applyItems}
            bidDetail={bidDetailData}
            directAirRton={directAirRton}
          />
        )}

        {applyItems && (
          <QuotationFeeForm
            quotationFeeFormCategory={"freightCost"}
            watch={watch}
            setValue={setValue}
            templateType="bidApply"
            exchangeRateList={formExchangeRateList}
            applyShipmentFormFeeDataType={"freightFeeData"}
            control={control}
            applyItems={applyItems}
            bidDetail={bidDetailData}
            directAirRton={directAirRton}
          />
        )}

        {applyItems && (
          <QuotationFeeForm
            quotationFeeFormCategory={"domesticCost"}
            watch={watch}
            setValue={setValue}
            templateType="bidApply"
            exchangeRateList={formExchangeRateList}
            applyShipmentFormFeeDataType={"domesticFeeData"}
            control={control}
            applyItems={applyItems}
            bidDetail={bidDetailData}
            directAirRton={directAirRton}
          />
        )}

        {applyItems && (
          <QuotationFeeForm
            quotationFeeFormCategory={"inlandCost"}
            watch={watch}
            setValue={setValue}
            templateType="bidApply"
            exchangeRateList={formExchangeRateList}
            applyShipmentFormFeeDataType={"inlandFeeData"}
            control={control}
            applyItems={applyItems}
            bidDetail={bidDetailData}
            directAirRton={directAirRton}
          />
        )}

        {applyItems && (
          <QuotationFeeForm
            quotationFeeFormCategory={"otherCost"}
            watch={watch}
            setValue={setValue}
            templateType="bidApply"
            exchangeRateList={formExchangeRateList}
            applyShipmentFormFeeDataType={"otherFeeData"}
            control={control}
            applyItems={applyItems}
            bidDetail={bidDetailData}
            directAirRton={directAirRton}
          />
        )}

        {applyItems && (
          <QuotationFeeForm
            quotationFeeFormCategory={"tax"}
            watch={watch}
            setValue={setValue}
            templateType="bidApply"
            exchangeRateList={formExchangeRateList}
            applyShipmentFormFeeDataType={"taxFeeData"}
            control={control}
            applyItems={applyItems}
            bidDetail={bidDetailData}
            directAirRton={directAirRton}
          />
        )}
      </Box>

      <RequestButtonAndTotalPriceBottomStickyBar
        invoiceState={watch()}
        shipmentDetailData={bidDetailData}
        invoiceData={invoiceData}
        directAirRton={directAirRton}
        exchangeRateDate={exchangeRateDate}
        sessionInvoiceId={sessionInvoiceId}
        onSessionInvoiceChange={onSessionInvoiceChange}
      />
    </Box>
  );
};

export default TradingStatementInfo;
