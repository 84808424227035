import { useMemo } from "react";
import { useParams } from "react-router-dom";

import { Country, Port } from "@sellernote/_shared/src/types/common/common";
import { InvoiceData } from "@sellernote/_shared/src/types/forwarding/tradingStatement";
import {
  ExchangeRate,
  InvoiceType,
  TrelloBidDetail,
} from "@sellernote/_shared/src/types/forwarding/trello";
import {
  getDefaultFeeData,
  getInvoiceExchangeRateList,
  getPartnerNameOrBRNNumber,
  getTradingStatementArrivalData,
  getTradingStatementComment,
  getTradingStatementCountryName,
  getTradingStatementFeeData,
  getTradingStatementInitialCbm,
  getTradingStatementInitialPackageValue,
  getTradingStatementInitialTon,
  getTradingStatementPortName,
} from "@sellernote/_shared/src/utils/forwarding/admin/tradingStatement";

/**
 * 견적 확정 데이터를 거래명세서용 폼 데이터의 defaultValue로 변환하고 리턴한다.
 */
export default function useGetFormDefaultValue({
  shipmentDetailData,
  portData,
  countryList,
  invoiceData,
  initialExchangeRateList,
}: {
  shipmentDetailData: TrelloBidDetail;
  portData: Port[];
  countryList: Country[];
  invoiceData: InvoiceData | undefined;
  initialExchangeRateList: ExchangeRate[];
}) {
  const { invoiceType } = useParams<{
    invoiceType: InvoiceType;
  }>();

  const { tradingStatementFeeData } = useMemo(() => {
    return getTradingStatementFeeData(shipmentDetailData);
  }, [shipmentDetailData]);

  const tradingStatementComment = useMemo(() => {
    return invoiceData
      ? invoiceData.comment
      : getTradingStatementComment({
          invoiceType,
          serviceType: shipmentDetailData.serviceType,
        });
  }, [invoiceData, invoiceType, shipmentDetailData.serviceType]);

  const quotationsUser = shipmentDetailData.quotationsUser[0];

  /** react-hook-form의 defaultValue로 사용할 데이터 */
  const formDefaultValue = {
    domesticFeeData: getDefaultFeeData({
      invoiceType,
      invoiceData,
      feeDataType: "domesticFee",
      tradingStatementFeeData,
      exchangeRateList: initialExchangeRateList,
      shipmentDetailData,
    }),
    freightFeeData: getDefaultFeeData({
      invoiceType,
      invoiceData,
      feeDataType: "freightFee",
      tradingStatementFeeData,
      exchangeRateList: initialExchangeRateList,
      shipmentDetailData,
    }),
    localFeeData: getDefaultFeeData({
      invoiceType,
      invoiceData,
      feeDataType: "localFee",
      tradingStatementFeeData,
      exchangeRateList: initialExchangeRateList,
      shipmentDetailData,
    }),
    otherFeeData: getDefaultFeeData({
      invoiceType,
      invoiceData,
      feeDataType: "otherFee",
      tradingStatementFeeData,
      exchangeRateList: initialExchangeRateList,
      shipmentDetailData,
    }),
    taxFeeData: getDefaultFeeData({
      invoiceType,
      invoiceData,
      feeDataType: "taxFee",
      tradingStatementFeeData,
      exchangeRateList: initialExchangeRateList,
      shipmentDetailData,
    }),
    inlandFeeData: getDefaultFeeData({
      invoiceType,
      invoiceData,
      feeDataType: "inlandFee",
      tradingStatementFeeData,
      exchangeRateList: initialExchangeRateList,
      shipmentDetailData,
    }),
    arrivalDate: getTradingStatementArrivalData(
      invoiceData,
      shipmentDetailData.management
    ),
    /**
     * 수출 의뢰 > 거래명세서 > 선적일
     * 선적일이 있으면 선적일을 사용, 없으면 ETD를 사용한다
     */
    onboardDate: invoiceData?.onboardDate
      ? invoiceData?.onboardDate
      : shipmentDetailData.management.fullETD ?? "",
    comment: tradingStatementComment,
    startPort: getTradingStatementPortName(
      quotationsUser.startPortId,
      portData
    ),
    endPort: getTradingStatementPortName(quotationsUser.endPortId, portData),

    cbm: getTradingStatementInitialCbm({
      shipmentDetailData,
      invoiceData,
    }),

    ton: getTradingStatementInitialTon({
      shipmentDetailData,
      invoiceData,
    }),
    blNumber:
      shipmentDetailData.management.BLType === "MBL" ||
      shipmentDetailData.management.BLType === "DirectMBL"
        ? shipmentDetailData.management.mBL
        : shipmentDetailData.management.hBL,
    packageValue: getTradingStatementInitialPackageValue({
      shipmentDetailData,
      invoiceData,
    }),
    startCountryName: getTradingStatementCountryName(
      countryList,
      shipmentDetailData.startCountry
    ),
    endCountryName: getTradingStatementCountryName(
      countryList,
      shipmentDetailData.endCountry
    ),
    voyageNo: shipmentDetailData.management.voyageNumber
      ? shipmentDetailData.management.voyageNumber
      : "-",
    shipName: shipmentDetailData.management.shipName
      ? shipmentDetailData.management.shipName
      : "",
    invoiceExchangeRateList: getInvoiceExchangeRateList({
      invoiceType,
      exchangeRateData: initialExchangeRateList,
      invoiceData,
    }),

    checkDisabled: true,
    recipient: invoiceData
      ? invoiceData.recipient
      : getPartnerNameOrBRNNumber(invoiceType, shipmentDetailData, "name"),
    userBRN: invoiceData
      ? invoiceData.BRN
      : getPartnerNameOrBRNNumber(invoiceType, shipmentDetailData, "BRNNumber"),
    containersNumberInfo: shipmentDetailData.isImport
      ? invoiceData?.containersNumberInfo?.map((v) => {
          return {
            containersNumber: v,
          };
        }) ?? []
      : shipmentDetailData.containersNumberInfo?.map((v) => {
          return {
            containersNumber: v,
          };
        }) ?? [],
  };

  return { formDefaultValue };
}
