import { useMemo } from "react";
import { Typography } from "@mui/material";

import { PagingResponseForBoard } from "@sellernote/_shared/src/types/common/common";
import { BusinessManagementDbListItem } from "@sellernote/_shared/src/types/forwarding/businessManagement";
import { toFormattedDate } from "@sellernote/_shared/src/utils/common/date";
import { changeUserDBFreightTypeToKr } from "@sellernote/_shared/src/utils/forwarding/admin/adminUser";
import Table, {
  TableBodyRow,
  TableHeadCell,
} from "@sellernote/_shared-for-forwarding-admin/src/components/Table";

type CellId = keyof BusinessManagementDbListItem;

const DBManagementTable = ({
  perPage,
  setPerPage,
  setPage,
  page,
  businessManagementDbList,
}: {
  perPage: number;
  setPerPage: (value: number) => void;
  setPage: (value: number) => void;
  page: number;
  businessManagementDbList:
    | PagingResponseForBoard<BusinessManagementDbListItem>
    | undefined;
}) => {
  const headCells: TableHeadCell<CellId>[] = useMemo(() => {
    return [
      {
        id: "forwardingManager",
        disablePadding: false,
        label: "담당자",
      },
      {
        id: "createdAt",
        disablePadding: false,
        label: "가입일",
      },
      {
        id: "company",
        disablePadding: false,
        label: "회사명",
      },
      {
        id: "name",
        disablePadding: false,
        label: "성함",
      },
      {
        id: "phone",
        disablePadding: false,
        label: "연락처",
      },
      {
        id: "freightType",
        disablePadding: false,
        label: "화물타입",
      },
    ];
  }, []);

  const rows = useMemo(() => {
    if (!businessManagementDbList?.list) return [];

    return businessManagementDbList?.list.map((v) => {
      const row: TableBodyRow<CellId> = {
        forwardingManager: v.forwardingManager.name,
        createdAt: toFormattedDate(v.createdAt, "YYYY-MM-DD"),
        company: v.company,
        name: v.name,
        phone: v.phone,
        freightType: changeUserDBFreightTypeToKr(v.freightType),
      };

      return row;
    });
  }, [businessManagementDbList?.list]);

  return (
    <Table
      toolbarItems={{
        left: [
          <Typography key="total">
            총 {businessManagementDbList?.total || 0}건
          </Typography>,
        ],
      }}
      headCells={headCells}
      rows={rows}
      pagination={{
        totalCount: businessManagementDbList?.total || 0,
        perPage,
        setPerPage,
        currentPage: page,
        setCurrentPage: setPage,
      }}
    />
  );
};

export default DBManagementTable;
