import { useCallback } from "react";
import { useQueryClient } from "react-query";
import { Button, CircularProgress } from "@mui/material";
import { Modal } from "antd";
import { AxiosResponse } from "axios";

import { RequestInvoiceData } from "@sellernote/_shared/src/api-interfaces/shipda-api/adminFile";
import { ADMIN_BID_QUERY_KEY_GEN } from "@sellernote/_shared/src/queries/forwarding/ADMIN_BID_QUERY";
import ADMIN_FILE_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_FILE_QUERY";
import TRELLO_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/TRELLO_BID_QUERY";
import { ResponseFailureInfo } from "@sellernote/_shared/src/types/common/common";
import { ApplyBidFormData } from "@sellernote/_shared/src/types/forwarding/adminBid";
import {
  InvoiceType,
  TrelloBidDetail,
} from "@sellernote/_shared/src/types/forwarding/trello";
import { checkIfTradingStatementCanBeSendToCustomsBroker } from "@sellernote/_shared/src/utils/forwarding/admin/tradingStatement";
import {
  checkHasPurchasePartner,
  checkPurchasePartnerDomainIsCustoms,
} from "@sellernote/_shared/src/utils/forwarding/admin/trello";
import useSnackbar from "@sellernote/_shared-for-forwarding-admin/src/hooks/useSnackbar";

import useCompareWithDefaultValue from "../../../hooks/useCompareWithDefaultValue";
import useCommonErrorMessage from "../hooks/useCommonErrorMessage";

function CustomsInvoiceButton({
  invoiceType,
  getInvoiceDataForRequest,
  handleRequestError,
  shipmentDetailData,
  invoiceState,
  isIssuedInvoice,
  isIssuedBidInvoice,
  directAirRton,
  sessionInvoiceId,
  onSessionInvoiceChange,
}: {
  invoiceType: InvoiceType;
  getInvoiceDataForRequest: (isTemporary: boolean) => RequestInvoiceData;
  handleRequestError: (
    response: AxiosResponse<ResponseFailureInfo, any> | undefined
  ) => void;
  shipmentDetailData: TrelloBidDetail;
  invoiceState: ApplyBidFormData;
  isIssuedInvoice: boolean;
  isIssuedBidInvoice: boolean;
  directAirRton: number;
  sessionInvoiceId: string | null;
  onSessionInvoiceChange: (invoiceId: string) => void;
}) {
  const { handleSnackbarOpen } = useSnackbar();

  const queryClient = useQueryClient();

  const { mutate: saveInvoice, isLoading: saveInvoiceLoading } =
    ADMIN_FILE_QUERY.useSaveInvoice();

  const { mutate: editInvoice, isLoading: editInvoiceLoading } =
    ADMIN_FILE_QUERY.useEditInvoice();

  const { checkAndShowCommonErrorMessage } = useCommonErrorMessage();

  const { mutate: sendCustomsInvoice } = ADMIN_FILE_QUERY.useSendCustomsInvoice(
    {
      bidId: shipmentDetailData.id,
    }
  );

  const { isCargoInfoUnchanged, isExchangeInfoUnchanged } =
    useCompareWithDefaultValue({
      invoiceState,
      shipmentDetailData,
      directAirRton,
    });

  const handleSuccess = useCallback(
    ({ data }) => {
      const invoiceId = data.id;

      sendCustomsInvoice(
        {
          invoiceType,
        },
        {
          onSuccess: () => {
            if (sessionInvoiceId) {
              queryClient.invalidateQueries(
                ADMIN_BID_QUERY_KEY_GEN.getAdminInvoice({
                  invoiceId,
                })
              );
            } else {
              onSessionInvoiceChange(invoiceId);
            }

            handleSnackbarOpen(
              shipmentDetailData.management.documentMailingFlag
                ? "거래명세서를 관세사에게 발송했습니다."
                : "화주에게 발송했습니다."
            );
          },

          onError: ({ response }) => {
            handleRequestError(response);
          },
        }
      );
    },
    [
      handleRequestError,
      handleSnackbarOpen,
      invoiceType,
      onSessionInvoiceChange,
      queryClient,
      sendCustomsInvoice,
      sessionInvoiceId,
      shipmentDetailData.management.documentMailingFlag,
    ]
  );

  const requestCustomsInvoice = useCallback(() => {
    if (sessionInvoiceId) {
      editInvoice(
        {
          pathParams: {
            invoiceId: Number(sessionInvoiceId),
          },
          invoiceData: getInvoiceDataForRequest(false),
          isTemporary: false,
          invoiceType,
        },
        {
          onSuccess: handleSuccess,

          onError: ({ response }) => {
            handleRequestError(response);
          },
        }
      );
    } else {
      saveInvoice(
        {
          invoiceData: getInvoiceDataForRequest(false),
          isTemporary: false,
          invoiceType,
        },
        {
          onSuccess: handleSuccess,

          onError: ({ response }) => {
            handleRequestError(response);
          },
        }
      );
    }
  }, [
    editInvoice,
    getInvoiceDataForRequest,
    handleRequestError,
    handleSuccess,
    invoiceType,
    saveInvoice,
    sessionInvoiceId,
  ]);

  const { mutate: changeCustomsPaymentFlag } =
    TRELLO_BID_QUERY.useChangeCustomsPaymentFlag(shipmentDetailData.id);

  const handleCustomsPartnerModalButtonClick = useCallback(
    (flag: boolean) => {
      changeCustomsPaymentFlag(
        { customsPaymentFlag: flag },
        {
          onSuccess: () => {
            requestCustomsInvoice();
          },

          onError: () => {
            handleSnackbarOpen("물류비 대납변경에 실패했습니다.", "error");
          },
        }
      );
    },
    [changeCustomsPaymentFlag, handleSnackbarOpen, requestCustomsInvoice]
  );

  const handleCustomsInvoiceRequestClick = useCallback(() => {
    if (
      checkAndShowCommonErrorMessage({
        isCargoInfoUnchanged,
        isExchangeInfoUnchanged,
        isIssuedInvoice,
        isIssuedBidInvoice,
        isValidateBRN: invoiceState.userBRN.length !== 10,
        canBeSendToCustomsBroker:
          checkIfTradingStatementCanBeSendToCustomsBroker(shipmentDetailData),
        hasDirectAirRton:
          shipmentDetailData.freightType === "AIR" && !directAirRton,
      })
    ) {
      return;
    }

    if (!shipmentDetailData.management.documentMailingFlag) {
      return Modal.confirm({
        okText: "확인",
        cancelText: "취소",
        closable: true,
        content: "통관서류 및 거래명세서가 고객 이메일로 발송됩니다.",
        onOk: () => {
          requestCustomsInvoice();
        },
      });
    }

    if (
      !checkHasPurchasePartner(shipmentDetailData.accountPayables, "customs")
    ) {
      handleSnackbarOpen("관세사를 선택해주세요", "error");
      return;
    }

    if (
      checkPurchasePartnerDomainIsCustoms(shipmentDetailData.accountPayables)
    ) {
      Modal.confirm({
        okText: "예",
        cancelText: "아니오",
        closable: true,
        content: "화주자체관세사가 고객의 물류비를 대납합니다",
        onOk: () => {
          handleCustomsPartnerModalButtonClick(true);
        },
        onCancel: (close) => {
          if (!close.triggerCancel) {
            handleCustomsPartnerModalButtonClick(false);
            close();
          }
        },
      });
    } else {
      requestCustomsInvoice();
    }
  }, [
    checkAndShowCommonErrorMessage,
    isCargoInfoUnchanged,
    isExchangeInfoUnchanged,
    isIssuedInvoice,
    isIssuedBidInvoice,
    invoiceState.userBRN.length,
    shipmentDetailData,
    directAirRton,
    requestCustomsInvoice,
    handleSnackbarOpen,
    handleCustomsPartnerModalButtonClick,
  ]);

  return (
    <Button
      disabled={saveInvoiceLoading || editInvoiceLoading}
      variant="contained"
      color="success"
      onClick={handleCustomsInvoiceRequestClick}
    >
      {saveInvoiceLoading || editInvoiceLoading ? (
        <CircularProgress size={25} />
      ) : (
        "관세사 발송"
      )}
    </Button>
  );
}

export default CustomsInvoiceButton;
