import { useCallback, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { Button, Checkbox } from "@mui/material";

import useSet from "@sellernote/_shared/src/hooks/common/useSet";
import ADMIN_COMMON_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_COMMON_QUERY";
import ADMIN_ORDER_QUERY, {
  ADMIN_ORDER_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/ADMIN_ORDER_QUERY";
import PARTNER_QUERY from "@sellernote/_shared/src/queries/forwarding/PARTNER_QUERY";
import {
  PartnerListItem,
  PartnerManagerItem,
} from "@sellernote/_shared/src/types/forwarding/partner";
import { getMainManagerInfo } from "@sellernote/_shared/src/utils/forwarding/admin/partner";
import Modal from "@sellernote/_shared-for-admin/src/components/Modal";
import LegacyTable, {
  LegacyTableBodyRow,
  LegacyTableHeadCell,
} from "@sellernote/_shared-for-forwarding-admin/src/components/LegacyTable";
import PartnerManagersListModal from "@sellernote/_shared-for-forwarding-admin/src/containers/PartnerManagersListModal";
import useGetObjectWithTermSearchTypeKey from "@sellernote/_shared-for-forwarding-admin/src/hooks/useGetObjectWithTermSearchTypeKey";
import useSearchWithTerm, {
  TermSearchType,
} from "@sellernote/_shared-for-forwarding-admin/src/hooks/useSearchWithTerm";
import useSnackbar from "@sellernote/_shared-for-forwarding-admin/src/hooks/useSnackbar";

import { renderCountryInCharge } from "pages/partner/utils";

type CellId =
  | keyof PartnerListItem
  | "mainManagerName"
  | "mainManagerPosition"
  | "mainManagerPhone"
  | "mainManagerEmail"
  | "showsManagersModal"
  | "checkbox";

const termSearchTypeOptions: TermSearchType<"companyName">[] = [
  {
    label: "회사명",
    value: "companyName",
  },
];

function OrderPartnerListModal({
  POId,
  showsOrderPartnerModal,
  setShowsOrderPartnerModal,
  businessArea,
}: {
  POId: number;
  showsOrderPartnerModal: boolean;
  setShowsOrderPartnerModal: React.Dispatch<React.SetStateAction<boolean>>;
  businessArea: "domestic" | "foreign" | undefined;
}) {
  const { handleSnackbarOpen } = useSnackbar();

  const queryClient = useQueryClient();

  const [currentPage, setCurrentPage] = useState(0);

  const [perPage, setPerPage] = useState(25);
  const [showsPartnerManagersListModal, setShowsPartnerManagersListModal] =
    useState(false);
  const [managers, setManagers] = useState<PartnerManagerItem[]>([]);

  const { mutate: updateOrderPartner } =
    ADMIN_ORDER_QUERY.useUpdateOrderPartner();

  const { data: countryData = [] } = ADMIN_COMMON_QUERY.useGetCountryList();

  const { set: checkBoxSet, init: initCheckBoxSet } = useSet<number>();

  const {
    array: managerCheckBoxArr,
    set: managerCheckBoxSet,
    toggle: toggleManagerCheckBox,
    init: initManagerCheckBoxSet,
  } = useSet<number>();

  const { debouncedSearchTerm, termSearchType, TermSearchPanel } =
    useSearchWithTerm({
      termSearchTypeOptions,
    });

  const { objectWithTermSearchTypeKey } = useGetObjectWithTermSearchTypeKey({
    termSearchType,
    debouncedSearchTerm,
  });

  const handleOrderPartnerUpdate = useCallback(
    ({
      businessArea,
      poId,
      partnerManagersIds,
    }: {
      businessArea: "domestic" | "foreign";
      poId: number;
      partnerManagersIds: number[];
    }) => {
      updateOrderPartner(
        {
          businessArea,
          poId,
          partnerManagersIds,
        },
        {
          onSuccess: () => {
            handleSnackbarOpen("요청에 성공했습니다.");
            queryClient.invalidateQueries(
              ADMIN_ORDER_QUERY_KEY_GEN.getAdminOrderDetail({
                id: POId,
              })
            );
          },

          onError: ({ response }) => {
            if (response?.data?.code === 369) {
              handleSnackbarOpen("발주서가 없습니다.", "error");
            } else if (response?.data?.code === 370) {
              handleSnackbarOpen("매니저가 없습니다.", "error");
            } else if (response?.data?.code === 400) {
              handleSnackbarOpen(
                "발주서가 종료 상태라 파트너 수정이 불가능 합니다.",
                "error"
              );
            } else {
              handleSnackbarOpen("요청에 실패했습니다.", "error");
            }
          },
        }
      );
    },
    [POId, handleSnackbarOpen, queryClient, updateOrderPartner]
  );

  const handlePartnerUpdate = useCallback(() => {
    handleOrderPartnerUpdate({
      businessArea: businessArea || "foreign",
      poId: POId,
      partnerManagersIds: managerCheckBoxArr,
    });
  }, [POId, businessArea, handleOrderPartnerUpdate, managerCheckBoxArr]);

  const { data: partnerList } = PARTNER_QUERY.useGetPartnerList({
    page: currentPage,
    perPage,
    businessArea,
    ...objectWithTermSearchTypeKey,
    enabled: true,
    region: "KR",
  });

  const handlePartnerManagersListModalOpen = useCallback(
    (managers: PartnerManagerItem[]) => {
      return () => {
        setManagers(managers);
        setShowsPartnerManagersListModal(true);
      };
    },
    []
  );

  const headCells: LegacyTableHeadCell<CellId>[] = useMemo(() => {
    return [
      { id: "checkbox", disablePadding: false, label: "선택" },
      { id: "name", disablePadding: false, label: "회사명" },
      { id: "language", disablePadding: false, label: "언어" },
      {
        id: "countries",
        disablePadding: false,
        label: "담당 국가",
        width: 140,
      },
      { id: "mainManagerName", disablePadding: false, label: "담당자 이름" },
      { id: "mainManagerPosition", disablePadding: false, label: "직함" },
      { id: "mainManagerPhone", disablePadding: false, label: "전화번호" },
      { id: "mainManagerEmail", disablePadding: false, label: "이메일" },
      { id: "showsManagersModal", disablePadding: false, label: "매니저" },
    ];
  }, []);

  const rows = useMemo(() => {
    if (!partnerList?.list) return [];

    return partnerList.list.map((partnerListItem) => {
      const row: LegacyTableBodyRow<CellId> = {
        checkbox: (
          <Checkbox
            checked={checkBoxSet.has(partnerListItem.id)}
            onClick={(e) => {
              e.stopPropagation();
              initCheckBoxSet([partnerListItem.id]);
              const idsToCheck = partnerListItem.managers.map(
                (managersItem) => {
                  return managersItem.id;
                }
              );
              initManagerCheckBoxSet(idsToCheck);
            }}
          />
        ),

        name: partnerListItem.name,
        language: partnerListItem.language,
        countries: renderCountryInCharge({
          countryDataList: countryData,
          countryInChargeOfPartner: partnerListItem.countries,
        }),
        mainManagerName: getMainManagerInfo("name", partnerListItem.managers),
        mainManagerPosition: getMainManagerInfo(
          "position",
          partnerListItem.managers
        ),
        mainManagerPhone: getMainManagerInfo("phone", partnerListItem.managers),
        mainManagerEmail: getMainManagerInfo("email", partnerListItem.managers),
        showsManagersModal: (
          <Button
            onClick={handlePartnerManagersListModalOpen(
              partnerListItem.managers
            )}
          >
            보기
          </Button>
        ),
      };
      return row;
    });
  }, [
    checkBoxSet,
    countryData,
    handlePartnerManagersListModalOpen,
    initCheckBoxSet,
    initManagerCheckBoxSet,
    partnerList?.list,
  ]);

  const handleModalClose = useCallback(() => {
    setShowsOrderPartnerModal(false);
  }, [setShowsOrderPartnerModal]);

  return (
    <Modal
      isOpened={showsOrderPartnerModal}
      handleClose={handleModalClose}
      modalBody={
        <>
          <LegacyTable
            toolbarItems={{
              left: [
                <Button
                  key="request"
                  variant="contained"
                  onClick={handlePartnerUpdate}
                >
                  파트너 선택
                </Button>,
                TermSearchPanel,
              ],
            }}
            sx={{ height: "500px", width: "1400px" }}
            title="의뢰 파트너 리스트"
            headCells={headCells}
            rows={rows}
            pagination={{
              totalCount: partnerList?.total || 0,
              perPage,
              setPerPage,
              currentPage,
              setCurrentPage,
            }}
          />

          {showsPartnerManagersListModal && (
            <PartnerManagersListModal
              showsPartnerManagersListModal={showsPartnerManagersListModal}
              setShowsPartnerManagersListModal={
                setShowsPartnerManagersListModal
              }
              managers={managers}
              partnerCheckboxSet={checkBoxSet}
              managerCheckBoxSet={managerCheckBoxSet}
              toggleManagerCheckBox={toggleManagerCheckBox}
              initManagerCheckBoxSet={initManagerCheckBoxSet}
            />
          )}
        </>
      }
    />
  );
}

export default OrderPartnerListModal;
