import { useCallback, useMemo, useState } from "react";
import { Box, Button } from "@mui/material";
import { Typography } from "@mui/material";
import { useSetRecoilState } from "recoil";

import ADMIN_SETTLEMENT_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_SETTLEMENT_QUERY";
import { FORWARDING_ADMIN_SETTLEMENT_ATOMS } from "@sellernote/_shared/src/states/forwarding/adminSettlement";
import { Currency } from "@sellernote/_shared/src/types/common/common";
import { DepositV2ListItem } from "@sellernote/_shared/src/types/forwarding/adminSettlement";
import { isEmptyObjectOrArray } from "@sellernote/_shared/src/utils/common/etc";
import { toThousandUnitFormat } from "@sellernote/_shared/src/utils/common/number";
import { changeInvoiceResultToKr } from "@sellernote/_shared/src/utils/forwarding/admin/adminSettlement";
import Table, {
  TableBodyRow,
  TableHeadCell,
} from "@sellernote/_shared-for-forwarding-admin/src/components/Table";

import MatchBidIdModal from "pages/settlement/deposit-management/MatchBidIdModal";
import TableMemoModal from "pages/settlement/TableMemoModal";

import MatchAmountPerInvoice from "./MatchAmountPerInvoice";
import useTableFilter from "./useTableFilter";

type CellId = keyof DepositV2ListItem;

function DepositManagementTable() {
  const [showsMatchBidIdModal, setShowsMatchBidIdModal] = useState(false);
  const [showsTableMemoModal, setShowsTableMemoModal] = useState(false);
  const [companyId, setCompanyId] = useState(0);
  const [paymentInvoiceId, setPaymentInvoiceId] = useState(0);
  const [depositAmount, setDepositAmount] = useState(0);
  const [depositName, setDepositName] = useState("");
  const [currency, setCurrency] = useState<Currency>("KRW");
  const [perPage, setPerPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(0);

  const setRequestInvoiceIds = useSetRecoilState(
    FORWARDING_ADMIN_SETTLEMENT_ATOMS.DEPOSIT_INVOICE_ID
  );

  const setRequestAmount = useSetRecoilState(
    FORWARDING_ADMIN_SETTLEMENT_ATOMS.DEPOSIT_REQUEST_AMOUNT
  );

  const {
    /** 셀렉트 + 텍스트필드 필터(입금자명, 입금액)  */
    objectWithTermSearchTypeKey,
    TermSearchPanel,
    /** 입금일 날짜 필터 */
    DateSearchPanel,
    dateSearchType,
    startDate,
    endDate,

    /** 은행명 테이블 헤드 필터 */
    BankNameFilterPanel,
    bankNameFilterData,

    /** 매칭결과 헤드 필터 */
    DepositResultFilterPanel,
    depositResultFilterData,

    /** 청구서별 매칭 상태 */
    InvoiceResultFilterPanel,
    invoiceResultFilterData,
  } = useTableFilter();

  const {
    data: settlementDepositV2Data,
    refetch: refetchSettlementDepositV2Data,
  } = ADMIN_SETTLEMENT_QUERY.useGetAdminSettlementDepositV2List({
    page: currentPage,
    perPage,
    // 쉽다 어드민 API에서는 단일값이므로 배열의 첫값을 사용
    ...objectWithTermSearchTypeKey,
    bankName: bankNameFilterData ? bankNameFilterData[0] : undefined,
    ...(depositResultFilterData ? { depositResultFilterData } : {}),
    ...(invoiceResultFilterData ? { invoiceResultFilterData } : {}),
    ...(dateSearchType && startDate && endDate
      ? {
          fromDate: new Date(startDate),
          toDate: new Date(endDate),
        }
      : {}),
  });

  const {
    refetch: refetchSettlementDepositV2ExcelData,
    ResponseHandler: SettlementDepositV2ExcelDataResponseHandler,
  } = ADMIN_SETTLEMENT_QUERY.useGetAdminSettlementDepositV2ExcelList({
    enabled: false,
  });

  const handleMatchBidIdModalOpen = useCallback(
    (depositListItem: DepositV2ListItem) => {
      const invoiceIdList = depositListItem.invoices.map((invoice) => {
        return invoice.invoiceId;
      });

      const requestAmount = depositListItem.invoices.reduce((acc, cur) => {
        acc += cur.matchedAmount;
        return acc;
      }, 0);

      setDepositAmount(depositListItem.depositAmount);
      setCompanyId(depositListItem.companyId || 0);
      setPaymentInvoiceId(depositListItem.id);
      // TODO: 실험 종료 후 전역 데이터명 수정
      setRequestInvoiceIds(invoiceIdList);
      setRequestAmount(requestAmount);
      setShowsMatchBidIdModal(true);
    },
    [setRequestAmount, setRequestInvoiceIds]
  );

  const handleTableMemoModalOpen = (depositListItem: DepositV2ListItem) => {
    return () => {
      setDepositAmount(depositListItem.depositAmount);
      setPaymentInvoiceId(depositListItem.id);
      setDepositName(depositListItem.depositName);
      setShowsTableMemoModal(true);
      setCurrency(depositListItem.currency);
    };
  };

  const headCells: TableHeadCell<CellId>[] = useMemo(() => {
    return [
      {
        id: "transactionDateTime",
        disablePadding: false,
        label: "일시",
        width: 120,
      },
      {
        id: "bankName",
        disablePadding: false,
        label: "수취은행",
        width: 150,
        filter: BankNameFilterPanel,
      },
      {
        id: "depositName",
        disablePadding: false,
        label: "입금자명",
        width: 120,
      },
      {
        id: "currency",
        disablePadding: false,
        label: "통화",
        width: 100,
      },
      {
        id: "depositAmount",
        disablePadding: false,
        label: "입금액",
        numeric: true,
        width: 150,
      },
      {
        id: "giroBankName",
        disablePadding: false,
        label: "송금은행",
        width: 150,
      },
      {
        id: "invoices",
        disablePadding: false,
        label: "청구서별 매칭 금액 / 상태",
        filter: InvoiceResultFilterPanel,
      },
      {
        id: "depositResult",
        disablePadding: false,
        label: "결과",
        width: 100,
        filter: DepositResultFilterPanel,
      },
      {
        id: "comment",
        disablePadding: false,
        label: "메모",
        width: 100,
      },
    ];
  }, [BankNameFilterPanel, DepositResultFilterPanel, InvoiceResultFilterPanel]);

  const rows = useMemo(() => {
    if (!settlementDepositV2Data?.list) return [];

    return settlementDepositV2Data?.list.map((v) => {
      const row: TableBodyRow<CellId> = {
        transactionDateTime: v.transactionDateTime,
        bankName: v.bankName,
        depositName: v.depositName,
        currency: v.currency,
        depositAmount: (
          <Box>
            <Typography variant="body2">
              {toThousandUnitFormat(v.depositAmount)}
            </Typography>
            {v.depositRemainingAmount ? (
              <Typography color="orange" variant="body2">
                초과 ({toThousandUnitFormat(v.depositRemainingAmount)})
              </Typography>
            ) : null}
          </Box>
        ),
        giroBankName: v.giroBankName,
        invoices: isEmptyObjectOrArray(v.invoices) ? (
          <Button
            onClick={() => handleMatchBidIdModalOpen(v)}
            variant={"text"}
            size="small"
          >
            {"등록"}
          </Button>
        ) : (
          <MatchAmountPerInvoice
            depositV2ListItem={v}
            handleMatchBidIdModalOpen={handleMatchBidIdModalOpen}
          />
        ),
        depositResult: changeInvoiceResultToKr(v.depositResult),
        comment: (
          <Button onClick={handleTableMemoModalOpen(v)}>
            {v.comment ? "확인" : "등록"}
          </Button>
        ),
      };

      return row;
    });
  }, [handleMatchBidIdModalOpen, settlementDepositV2Data?.list]);

  return (
    <>
      <Table
        toolbarItems={{
          left: [TermSearchPanel, DateSearchPanel],
          right: [
            <Button
              key={"excel"}
              variant="contained"
              onClick={() => {
                refetchSettlementDepositV2ExcelData();
              }}
            >
              엑셀 다운로드
            </Button>,
          ],
        }}
        title={"입금관리 실험실"}
        headCells={headCells}
        rows={rows}
        pagination={{
          rowsPerPageOptions: [10, 25, 50, 100, 500, 1000, 10000],
          totalCount: settlementDepositV2Data?.total || 0,
          perPage,
          setPerPage,
          currentPage,
          setCurrentPage,
        }}
      />

      {showsMatchBidIdModal && (
        <MatchBidIdModal
          showsMatchBidIdModal={showsMatchBidIdModal}
          setShowsMatchBidIdModal={setShowsMatchBidIdModal}
          companyId={companyId}
          paymentInvoiceId={paymentInvoiceId}
          setCompanyId={setCompanyId}
          depositAmount={depositAmount}
          // 실험 종료 후 V2 제거 예정
          refetchSettlementDepositList={refetchSettlementDepositV2Data}
        />
      )}

      {showsTableMemoModal && (
        <TableMemoModal
          showsTableMemoModal={showsTableMemoModal}
          setShowsTableMemoModal={setShowsTableMemoModal}
          name={depositName}
          paymentInvoiceId={paymentInvoiceId}
          amount={depositAmount}
          refetchListData={refetchSettlementDepositV2Data}
          settlementDepositV2Data={settlementDepositV2Data?.list}
          type="depositV2"
          currency={currency}
        />
      )}

      {SettlementDepositV2ExcelDataResponseHandler}
    </>
  );
}

export default DepositManagementTable;
