import { useCallback, useMemo } from "react";
import { UseFormSetValue } from "react-hook-form";
import { useAtom } from "jotai";

import { ApplyBidFormData } from "@sellernote/_shared/src/types/forwarding/adminBid";
import { InvoiceData } from "@sellernote/_shared/src/types/forwarding/tradingStatement";
import { TrelloBidDetail } from "@sellernote/_shared/src/types/forwarding/trello";
import {
  extractNumberFromRtonString,
  getTradingStatementInitialCbm,
  getTradingStatementInitialPackageValue,
  getTradingStatementInitialTon,
} from "@sellernote/_shared/src/utils/forwarding/admin/tradingStatement";
import { FORWARDING_ADMIN_TRADING_STATEMENT_ATOMS } from "@sellernote/_shared-for-forwarding-admin/src/jotaiStates/tradingStatement";

import {
  applyExchangeAndCargoUpdatesToAllFeeData,
  resetCargoInfo,
} from "../utils";

/**
 * 화물 정보의 로직을 분리하는 커스텀 훅
 */
export default function useCargoInfoLogic({
  invoiceState,
  shipmentDetailData,
  directAirRton,
  handleDirectAirRtonChange,
  setValue,
  invoiceData,
}: {
  invoiceState: ApplyBidFormData;
  shipmentDetailData: TrelloBidDetail;
  directAirRton: number;
  handleDirectAirRtonChange: (rton: number) => void;
  setValue: UseFormSetValue<ApplyBidFormData>;
  invoiceData: InvoiceData | undefined;
}) {
  const [defaultCbm, setDefaultCbm] = useAtom(
    FORWARDING_ADMIN_TRADING_STATEMENT_ATOMS.TRADING_STATEMENT_DEFAULT_CBM
  );

  const [defaultTon, setDefaultTon] = useAtom(
    FORWARDING_ADMIN_TRADING_STATEMENT_ATOMS.TRADING_STATEMENT_DEFAULT_TON
  );

  const [defaultPackageType, setDefaultPackageType] = useAtom(
    FORWARDING_ADMIN_TRADING_STATEMENT_ATOMS.TRADING_STATEMENT_DEFAULT_PACKAGE
  );

  const [defaultDirectRton, setDefaultDirectRton] = useAtom(
    FORWARDING_ADMIN_TRADING_STATEMENT_ATOMS.TRADING_STATEMENT_DEFAULT_DIRECT_RTON
  );

  const handleResetCargoInfoClick = useCallback(() => {
    resetCargoInfo({
      shipmentDetailData,
      setValue,
      invoiceData,
      handleDirectAirRtonChange,
    });
  }, [handleDirectAirRtonChange, invoiceData, setValue, shipmentDetailData]);

  const handleApplyCargoInfoClick = useCallback(() => {
    applyExchangeAndCargoUpdatesToAllFeeData({
      invoiceState,
      shipmentDetailData,
      directAirRton,
      setValue,
      cbm: invoiceState.cbm,
      ton: invoiceState.ton,
      exchangeRateList: invoiceState.invoiceExchangeRateList,
    });

    // 적용하기를 하는 경우 기본값을 현재값으로 다시 셋팅
    setDefaultTon(invoiceState.ton);

    setDefaultPackageType(invoiceState.packageValue);

    if (shipmentDetailData.freightType === "AIR") {
      setDefaultDirectRton(directAirRton);
      return;
    }

    setDefaultCbm(invoiceState.cbm);
  }, [
    directAirRton,
    invoiceState,
    setDefaultCbm,
    setDefaultDirectRton,
    setDefaultPackageType,
    setDefaultTon,
    setValue,
    shipmentDetailData,
  ]);

  /**
   * 초기값과 기준값을 비교해서 다른 점이 하나라도 있으면 초기화 버튼 활성화
   * 입력값과 기준값을 비교해서 다른 점이 하나라도 있으면 초기화 버튼 활성화
   * AIR일때만 초기 DirectRton값과 입력 값을 비교
   */
  const isResetButtonDisabled = useMemo(() => {
    const initialTon = getTradingStatementInitialTon({
      shipmentDetailData,
      invoiceData,
    });

    const packageValue = getTradingStatementInitialPackageValue({
      shipmentDetailData,
      invoiceData,
    });

    const initialCbm = getTradingStatementInitialCbm({
      shipmentDetailData,
      invoiceData,
    });

    const isCommonValuesEqual =
      initialTon === defaultTon &&
      packageValue === defaultPackageType &&
      invoiceState.ton === defaultTon &&
      invoiceState.packageValue === defaultPackageType;

    if (shipmentDetailData.freightType === "AIR") {
      const initialDirectAirRton = invoiceData?.rton
        ? extractNumberFromRtonString(invoiceData.rton)
        : 0;

      return (
        directAirRton === defaultDirectRton &&
        directAirRton === initialDirectAirRton &&
        isCommonValuesEqual
      );
    }

    return (
      initialCbm === defaultCbm &&
      invoiceState.cbm === defaultCbm &&
      isCommonValuesEqual
    );
  }, [
    defaultCbm,
    defaultDirectRton,
    defaultPackageType,
    defaultTon,
    directAirRton,
    invoiceData,
    invoiceState.cbm,
    invoiceState.packageValue,
    invoiceState.ton,
    shipmentDetailData,
  ]);

  return {
    handleApplyCargoInfoClick,
    handleResetCargoInfoClick,
    isResetButtonDisabled,
  };
}
