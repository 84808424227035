import { UseFormSetValue } from "react-hook-form";

import { ApplyBidFormData } from "@sellernote/_shared/src/types/forwarding/adminBid";
import { InvoiceData } from "@sellernote/_shared/src/types/forwarding/tradingStatement";
import {
  ExchangeRate,
  TrelloBidDetail,
} from "@sellernote/_shared/src/types/forwarding/trello";
import {
  extractNumberFromRtonString,
  getReCalculatedFeeData,
  getTradingStatementInitialCbm,
  getTradingStatementInitialPackageValue,
  getTradingStatementInitialTon,
} from "@sellernote/_shared/src/utils/forwarding/admin/tradingStatement";

/** 환율정보와 화물정보의 변경을 전체 항목에 적용한다. */
const applyExchangeAndCargoUpdatesToAllFeeData = ({
  invoiceState,
  shipmentDetailData,
  directAirRton,
  setValue,
  cbm,
  ton,
  exchangeRateList,
}: {
  invoiceState: ApplyBidFormData;
  shipmentDetailData: TrelloBidDetail;
  directAirRton: number;
  setValue: UseFormSetValue<ApplyBidFormData>;
  cbm: number;
  ton: number;
  exchangeRateList: ExchangeRate[];
}) => {
  setValue(
    "freightFeeData",
    getReCalculatedFeeData({
      feeDataList: invoiceState.freightFeeData,
      cbm,
      ton,
      shipmentDetailData,
      directAirRton,
      exchangeRateList,
    })
  );
  setValue(
    "domesticFeeData",
    getReCalculatedFeeData({
      feeDataList: invoiceState.domesticFeeData,
      cbm,
      ton,
      shipmentDetailData,
      directAirRton,
      exchangeRateList,
    })
  );
  setValue(
    "inlandFeeData",
    getReCalculatedFeeData({
      feeDataList: invoiceState.inlandFeeData,
      cbm,
      ton,
      shipmentDetailData,
      directAirRton,
      exchangeRateList,
    })
  );
  setValue(
    "localFeeData",
    getReCalculatedFeeData({
      feeDataList: invoiceState.localFeeData,
      cbm,
      ton,
      shipmentDetailData,
      directAirRton,
      exchangeRateList,
    })
  );
  setValue(
    "otherFeeData",
    getReCalculatedFeeData({
      feeDataList: invoiceState.otherFeeData,
      cbm,
      ton,
      shipmentDetailData,
      directAirRton,
      exchangeRateList,
    })
  );
  setValue(
    "taxFeeData",
    getReCalculatedFeeData({
      feeDataList: invoiceState.taxFeeData,
      cbm,
      ton,
      shipmentDetailData,
      directAirRton,
      exchangeRateList,
    })
  );
};

const resetCargoInfo = ({
  shipmentDetailData,
  setValue,
  invoiceData,
  handleDirectAirRtonChange,
}: {
  shipmentDetailData: TrelloBidDetail;
  setValue: UseFormSetValue<ApplyBidFormData>;
  invoiceData: InvoiceData | undefined;
  handleDirectAirRtonChange: (rton: number) => void;
}) => {
  const initialTon = getTradingStatementInitialTon({
    shipmentDetailData,
    invoiceData,
  });

  setValue("ton", initialTon);

  setValue(
    "packageValue",
    getTradingStatementInitialPackageValue({
      shipmentDetailData,
      invoiceData,
    })
  );

  // AIR일 때는 cbm을 사용하지 않고 직접입력한 rton(c.w) 리셋을 추가
  if (shipmentDetailData.freightType === "AIR") {
    const initialDirectAirRton = invoiceData?.rton
      ? extractNumberFromRtonString(invoiceData.rton)
      : 0;

    handleDirectAirRtonChange(initialDirectAirRton);

    return;
  }

  // AIR가 아닌 경우 CBM을 추가로 계산
  const initialCbm = getTradingStatementInitialCbm({
    shipmentDetailData,
    invoiceData,
  });

  setValue("cbm", initialCbm);
};

const changeExchangeRateList = ({
  currency,
  rate,
  invoiceState,
}: {
  currency: string;
  rate: number;
  invoiceState: ApplyBidFormData;
}) => {
  return invoiceState.invoiceExchangeRateList.map((v) => {
    if (v.currency === currency) {
      return {
        ...v,
        rate,
      };
    }
    return {
      ...v,
    };
  });
};

export {
  applyExchangeAndCargoUpdatesToAllFeeData,
  resetCargoInfo,
  changeExchangeRateList,
};
